@font-face {
  font-family: Gilroy;
  src: url("./Assets/fonts/Gilroy-Font/Gilroy-Regular.woff");
}

@font-face {
  font-family: Gilroy-Black;
  src: url("./Assets/fonts/Gilroy-Font/Gilroy-Black.woff");
}

@font-face {
  font-family: Gilroy-Bold;
  src: url("./Assets/fonts/Gilroy-Font/Gilroy-Bold.woff");
}
@font-face {
  font-family: Gilroy-Light;
  src: url("./Assets/fonts/Gilroy-Font/Gilroy-Light.woff");
}
@font-face {
  font-family: Gilroy-Thin;
  src: url("./Assets/fonts/Gilroy-Font/Gilroy-Thin.woff");
}
@font-face {
  font-family: Gilroy-SemiBold;
  src: url("./Assets/fonts/Gilroy-Font/Gilroy-SemiBold.woff");
}

 
