.chat-height {
  max-height: 100%;
}
.chat-container-height {
  height: -moz-calc(100vh - 105px);
  height: -webkit-calc(100vh - 105px);
  height: calc(100vh - 105px);
}
.flex-grow{
  flex-grow: 2;
}
.full-height{
  height: 100vh;
}
.chat-input-height{
  height: 80px;
}
.height-live {
  height: -moz-calc(100% - 80px);
  height: -webkit-calc(100% - 80px);
  height: calc(100% - 80px);
}
.chat-container-height-live {
  height: -moz-calc(100vh - 80px);
  height: -webkit-calc(100vh - 80px);
  height: calc(100vh - 80px);
}