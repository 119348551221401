
  .spin__slot {
    position: relative;
    display: inline-block;
    height: 150px;
    width: 150px;
  }
  
  .spin__section {
    position: absolute; 
     
    width: 150px;
    height: 150px;
    overflow: hidden;  
    color: white;
    font-family: sans-serif;
    text-align: center;
    font-size: 50px;
    line-height: 120px;
    cursor: default;
  }
  
  .spin__container {
    position: absolute;
    top: 2px;
    width: 100%;
    transition: top ease-in-out 0.5s;
    text-align: center;
  }
  
  .spin__roll {
    width: 215px;
    cursor: pointer;
    background-color: yellow;
    padding: 10px;
    text-align: center;
    font-size: 20px;
    border-radius: 20px;
    border: 3px solid black;
  }
  
  .spin__rolling {
    animation: blinkingText 1.2s infinite;
  }
  
  @keyframes blinkingText {
    0% {
      color: #000;
    }
    49% {
      color: #000;
    }
    60% {
      color: transparent;
    }
    99% {
      color: transparent;
    }
    100% {
      color: #000;
    }
  }